import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { renderPreviewComponents } from "../components";
import Layout from "../modules/layout";
import SEO from "../modules/seo";
const contentful = require("contentful");

const PreviewPage = ({ data: { allContentfulSiteSettings, microcopy } }) => {
  const siteSettings = allContentfulSiteSettings.edges[0].node;

  const localizedMicrocopy = {};

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === siteSettings.node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  siteSettings.microcopy = localizedMicrocopy;

  const client = contentful.createClient({
    space: "s4dl1flih98w",
    accessToken: "UvX9lnN7hSQJiu9R4CZs9WzGaRiGlVTW7L8i6Uqs4Ds",
    host: "preview.contentful.com",
    environment: "master",
  });
  const [contentComponents, setContentComponents] = useState([]);
  const [locale, setLocale] = useState("de-DE");

  useEffect(() => {
    const url = new URL(window.location);
    const contentType = url.searchParams.get("contentType");
    const componentId = url.searchParams.get("componentId");
    client
      .getEntries({
        content_type: contentType,
        include: 10,
      })
      .then(entries => {
        const entry = entries.items.find(entry => entry.sys.id === componentId);
        setLocale(entry.sys.locale);
        setContentComponents([entry]);
      })
      .catch(console.error);
  }, []);

  return (
    <Layout siteSettings={siteSettings}>
      <SEO title="Preview" />
      {contentComponents && renderPreviewComponents(contentComponents, locale)}
    </Layout>
  );
};

export const pageQuery = graphql`
  query siteSettingsPreview {
    microcopy: allContentfulMicrocopy {
      edges {
        node {
          node_locale
          key
          value
        }
      }
    }
    allContentfulSiteSettings {
      ...settings
    }
  }
`;

export default PreviewPage;
